<template>

    <header>
        <div class="hb-header">
            <a href="/">
                <img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon">
            </a>
            <ul class="main-nav-list">
                <li><a class="main-nav-link" href="#it-works">How it works</a></li>
                <li><a @click="handleClickGetApp" href="#" class="main-nav-link nav-cta">Get Addie App</a></li>
            </ul>
        </div>
        
    </header>

    <main>
        <div v-if="showLanding">
            <div class="several-column">
                <section class="top-spacer">.</section>
                <section class="hb-home">
                    <div class="hb-hero-info">
                        <h1 class="hb-hero-info-h1">addie</h1>
                        <h3 class="hb-hero-info-h3">make it your home.</h3>
                        <div class="hb-hero-banner-border"></div>
                    </div>

                    <!-- 3w TopCd R1 -->
                    <div v-if="!iconOne" class="sec-card b1-card-one-click-one"></div>
                    <div v-else-if="!iconOneBee" class="sec-card b1-card-one-click-bee"></div>
                    <div v-else-if="!iconTwo" class="sec-card b1-card-one-click-two"></div>
                    <div v-else-if="!iconThree" class="sec-card b1-card-one-click-three"></div>

                    <!-- 3w BtmCd R2  -->
                    <div v-if="!iconOne" class="sec-card b1-card-two-click-one"></div>
                    <div v-else-if="!iconOneBee" class="sec-card b1-card-two-click-bee"></div>
                    <div v-else-if="!iconTwo" class="sec-card b1-card-two-click-two"></div>
                    <div v-else-if="!iconThree" class="sec-card b1-card-two-click-three"></div>

                    <!-- 3w LftCd R3 -->
                    <div v-if="!iconOne" class="sec-card b1-card-three-click-one"></div>
                    <div v-else-if="!iconOneBee" class="sec-card b1-card-three-click-bee"></div>
                    <div v-else-if="!iconTwo" class="sec-card b1-card-three-click-two"></div>
                    <div v-else-if="!iconThree" class="sec-card b1-card-three-click-three"></div>
                    
                    <!-- 3w CenCd R3 -->
                    <div id="it-works" class="sec-card b1-card-four title">
                            <h1 class="title-h1">{{ cardOneTitle }}</h1>
                            <div ref="activeColorOne" class="title-no active">01</div>
                            <a v-if="iconOne" @click="handleClickOne" class="open-icon display-on"><div>+</div></a>
                            <div v-if="!iconOne" class="opened-icon"><div><img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon"></div></div>
                        </div>
                    
                    <!-- 3w RhtCd R3 -->
                    <div class="sec-card b1-card-five title">
                            <h1 class="title-h1"> {{ cardTwoTitle }} </h1>
                            <a v-if="iconOneBee" @click="handleClickOneBee" class="open-icon display-on"><div>+</div></a>
                            <a v-if="!iconOneBee" class="opened-icon"><div><img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon"></div></a>
                        </div>
                    
                    <!-- 3w LftCd R4 -->
                    <div class="sec-card b1-card-six title">
                            <h1 class="title-h1"> {{ cardThreeTitle }} </h1>
                            <div ref="activeColorTwo" class="title-no">02</div>
                            <a v-if="iconTwo" @click="handleClickTwo" class="open-icon display-on"><div>+</div></a>
                            <a v-if="!iconTwo" class="opened-icon"><div><img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon"></div></a>
                        </div>

                    <!-- 3w CenCd R4 -->
                    <div v-if="!iconOne" class="sec-card b1-card-seven-click-one description-card">
                            <h1 class="title-h1-dk"> {{ descSubTitleOne }} </h1>
                            <div class="scroll"> 
                                <div class="description-txt"> {{ descParaTextOneOne }} </div>
                                <div class="description-txt"> {{ descParaTextOneTwo }} </div>
                                <div class="description-txt"> {{ descParaTextOneThree }} </div>
                                <div class="description-txt"> {{ descParaTextOneFour }} </div>
                            </div>
                        </div>
                    <div v-else-if="!iconOneBee" class="sec-card b1-card-seven-click-bee"></div>
                    <div v-else-if="!iconTwo" class="sec-card b1-card-seven-click-two description-card">
                            <h1 class="title-h1-dk"> {{ descSubTitleTwo }} </h1>
                            <div class="scroll"> 
                                <div class="description-txt"> {{ descParaTextTwoOne }} </div>
                                <div class="description-txt"> {{ descParaTextTwoTwo }} </div>
                                <div class="description-txt"> {{ descParaTextTwoThree }} </div>
                                <div class="description-txt"> {{ descParaTextTwoFour }} </div>
                            </div>
                        </div>
                    <div v-else-if="!iconThree" class="sec-card b1-card-seven-click-three description-card">
                            <h1 class="title-h1-dk"> {{ descSubTitleThree }} </h1>
                             <div class="scroll"> 
                                <div class="description-txt"> {{ descParaTextThreeOne }} </div>
                                <div class="description-txt"> {{ descParaTextThreeTwo }} </div>
                                <div class="description-txt"> {{ descParaTextThreeThree }} </div>
                                <div class="description-txt"> {{ descParaTextThreeFour }} </div>
                            </div>
                        </div>
                    
                    <!-- 3w RhtCd R4 -->
                    <div v-if="!iconOne" class="sec-card b1-card-eight-click-one"></div>
                    <div v-else-if="!iconOneBee" class="sec-card b1-card-eight-click-bee description-card">
                            <h1 class="title-h1-dk"> {{ descSubTitleBee }} </h1>
                            <div class="scroll"> 
                                <div class="description-txt"> {{ descParaTextBeeOne }} </div>
                                <div class="description-txt"> {{ descParaTextBeeTwo }} </div>
                                <div class="description-txt"> {{ descParaTextBeeThree }} </div>
                                <div class="description-txt"> {{ descParaTextBeeFour }} </div>
                            </div>
                        </div>
                    <div v-else-if="!iconTwo" class="sec-card b1-card-eight-click-two carosel-card">
                    
                            <div v-if="mailorflyer">
                                <div><img src="../assets/photos/squares/ADDIE_MB_ALL.png" alt="flyer example" class="carosel-shot"></div>
                                </div>
                            <!-- <div v-else>
                                    <div v-if="flyerone"><img src="../assets/photos/Beach-Bash.png" alt="flyer example" class="carosel-shot"></div>
                                    <div v-if="flyertwo"><img src="../assets/photos/Business_Flyer.png" alt="flyer example" class="carosel-shot"></div>
                                    <div v-if="flyerthree"><img src="../assets/photos/Missing_Dog.png" alt="flyer example" class="carosel-shot"></div>
                                    <div v-if="flyerfour"><img src="../assets/photos/Campus_Wines_Flyer.png" alt="flyer example" class="carosel-shot"></div>
                                </div>
                            <a v-if="!mailorflyer" @click="handleFlyerClickRev" class="carosel-before-btn"><div>
                                        <span class="material-icons carosel-icon">navigate_before</span>
                                    </div></a>
                            <a  v-if="!mailorflyer" @click="handleFlyerClickFwd" class="carosel-next-btn"><div>
                                    <span class="material-icons carosel-icon">navigate_next</span>
                                </div></a>
                            <a v-if="mailorflyer" @click="chooseFlyer" class="chooser-btn"><div>
                                <span class="material-icons chooser-icon">style</span>
                                </div></a>
                            <a v-else @click="chooseMail" class="chooser-btn"><div>
                                <span class="material-icons chooser-icon">mail</span>
                                </div></a> -->
                        </div>
                    <div v-else-if="!iconThree" class="sec-card b1-card-eight-click-three"></div>

                    <!-- 3w LftCd R5 -->
                    <div class="sec-card b1-card-nine title">
                            <h1 class="title-h1"> {{ cardFourTitle }} </h1>
                            <div ref="activeColorThree" class="title-no">03</div>
                            <a v-if="iconThree" @click="handleClickThree" class="open-icon display-on"><div>+</div></a>
                            <a v-if="!iconThree" class="opened-icon"><div><img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon"></div></a>
                        </div>
                    
                    <!-- 3w CenCd R5 -->
                    <div v-if="!iconOne" class="sec-card b1-card-ten-click-one"></div>
                    <div v-else-if="!iconOneBee" class="sec-card b1-card-ten-click-bee"></div>
                    <div v-else-if="!iconTwo" class="sec-card b1-card-ten-click-two"></div>
                    <div v-else-if="!iconThree" class="sec-card b1-card-ten-click-three"></div>
                    
                    <!-- 3w RhtCd R5 -->
                    <div v-if="!iconOne" class="sec-card b1-card-eleven-click-one"></div>
                    <div v-else-if="!iconOneBee" class="sec-card b1-card-eleven-click-bee"></div>
                    <div v-else-if="!iconTwo" class="sec-card b1-card-eleven-click-two"></div>
                    <div v-else-if="!iconThree" class="sec-card b1-card-eleven-click-three"></div>

                    <!-- 3w LeftCd R6 -->
                    <div v-if="!iconOne" class="sec-card b1-card-twelve-click-one"></div>
                    <div v-else-if="!iconOneBee" class="sec-card b1-card-twelve-click-bee"></div>
                    <div v-else-if="!iconTwo" class="sec-card b1-card-twelve-click-two"></div>
                    <div v-else-if="!iconThree" class="sec-card b1-card-twelve-click-three"></div>
                    
                    <!-- 3w Footer -->
                    <div class="footer-card">
                        <div class="clear-card co-info">
                            <h1 class="footer-h1"> addie </h1>
                            <div>
                                <div class="footer-text smpl-bold">Home Sweet Oxford</div>
                                <a href="mailto:hello@getmyaddie.com"><div class="footer-text">hello@getmyaddie.com</div></a>
                                <div class="footer-text smpl-top-space-md">elsewhere:</div>
                                <div class="footer-text smpl-bold">@getmyaddie</div>
                            </div>
                        </div>

                        <div class="clear-card co-contact">
                            <h1 class="footer-h1-b dbl-right"> Contact Us </h1>
                            <div>
                                <!-- <a href="mailto:hello@getmyaddie.com"><div class="footer-text">hello@getmyaddie.com</div></a> -->
                                <a href="mailto:hello@getmyaddie.com" class="main-nav-link nav-cta smpl-top-space-sm">What's On Your Mind</a>
                                <a @click="handleShowTerms" href="#"><div class="footer-text dbl-right smpl-top-space-md">terms of service</div></a>
                                <a @click="handleShowPrivacy" href="#"><div class="footer-text dbl-right">privacy policy</div></a>
                            </div>
                        </div>
                    </div>

                </section>
            </div>

            <div class="mobile-column">
                <section class="top-spacer">.</section>
                <section class="hb-home">
                    <div class="hb-hero-info">
                        <h1 class="hb-hero-info-h1">addie</h1>
                        <h3 class="hb-hero-info-h3">make it your home.</h3>
                        <div class="hb-hero-banner-border"></div>
                    </div>
                    
                    <div class="sec-click-one">
                        
                        <div class="mobile-sec">
                            <div id="it-works mob-one-a" class="sec-card b1-card-four title">
                                <h1 class="title-h1">{{ cardOneTitle }}</h1>
                                <div ref="activeColorOneMob" class="title-no active">01</div>
                                <a v-if="!mobOne" @click="handleMobOne" href="#mob-one-a" class="open-icon display-on"><div>+</div></a>
                                <div v-if="mobOne" class="opened-icon"><div><img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon"></div></div>
                            </div>
                        </div>

                        <div v-if="mobOne" class="mobile-sec">
                            <div class="sec-card b1-card-one-click-one"></div>
                            <div class="sec-card b1-card-two-click-one"></div>
                        </div>

                        <div class="mobile-sec">
                            <div class="sec-card-mob-desc b1-card-seven-click-one-mob-desc description-card">
                                <h1 v-if="mobOne" class="title-h1-dk"> {{ descSubTitleOne }} </h1>
                                <h1 v-else class="title-h1-dk"> {{ descSubTitleOne }}... </h1>
                                <div class="scroll-mob">
                                    <div v-if="mobOne" class="description-txt"> {{ descParaTextOneOne }} </div>
                                    <div v-if="mobOne" class="description-txt"> {{ descParaTextOneTwo }} </div>
                                    <div v-if="mobOne" class="description-txt"> {{ descParaTextOneThree }} </div>
                                    <div v-if="mobOne" class="description-txt"> {{ descParaTextOneFour }} </div>
                                </div>
                                <a v-if="!mobOne" @click="handleMobOne" class="fly-btn"><div>
                                    <span class="material-icons fly-icon">expand_more</span>
                                </div></a>
                                <a v-if="mobOne" @click="handleMobBee" href="#mob-bee-a" class="fly-btn"><div>
                                    <span class="material-icons fly-icon">expand_more</span>
                                </div></a>
                            </div>
                            <div class="sec-card b1-card-three-click-one"></div>
                        </div>
                        <div v-if="mobOne" class="mobile-sec">
                            <div class="sec-card b1-card-eight-click-one"></div>
                        </div>

                    </div>

                    <div class="sec-click-bee">
                        
                        <div class="mobile-sec">
                            <div id="mob-bee-a" class="sec-card b1-card-five title no-no">
                                <h1 class="title-h1"> {{ cardTwoTitle }} </h1>
                                <a v-if="!mobBee" @click="handleMobBee" href="#mob-bee-a" class="open-icon display-on"><div>+</div></a>
                                <a v-if="mobBee" class="opened-icon"><div><img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon"></div></a>
                            </div>
                        </div>

                        <div v-if="mobBee" class="mobile-sec">
                            <div class="sec-card b1-card-seven-click-bee"></div>
                            <div class="sec-card b1-card-ten-click-bee"></div>
                        </div>

                        <div class="mobile-sec">
                            <div class="sec-card-mob-desc b1-card-seven-click-one-mob-desc description-card">
                                <h1 v-if="mobBee" class="title-h1-dk"> {{ descSubTitleBee }} </h1>
                                <h1 v-else class="title-h1-dk"> {{ descSubTitleBee }}... </h1>
                                <div class="scroll-mob">
                                    <div v-if="mobBee" class="description-txt"> {{ descParaTextBeeOne }} </div>
                                    <div v-if="mobBee" class="description-txt"> {{ descParaTextBeeTwo }} </div>
                                    <div v-if="mobBee" class="description-txt"> {{ descParaTextBeeThree }} </div>
                                    <div v-if="mobBee" class="description-txt"> {{ descParaTextBeeFour }} </div>
                                </div>    
                                <a v-if="!mobBee" @click="handleMobBee" class="fly-btn"><div>
                                    <span class="material-icons fly-icon">expand_more</span>
                                </div></a>
                                <a v-if="mobBee" @click="handleMobTwo" href="#mob-two-a" class="fly-btn"><div>
                                    <span class="material-icons fly-icon">expand_more</span>
                                </div></a>
                            </div>
                            <div class="sec-card b1-card-three-click-bee"></div>
                        </div>
                        <div v-if="mobBee" class="mobile-sec">
                            <div class="sec-card b1-card-two-click-bee"></div>
                        </div>

                    </div>

                    <div class="sec-click-two">
                        
                        <div class="mobile-sec">
                            <div id="mob-two-a" class="sec-card b1-card-six title">
                                <h1 class="title-h1"> {{ cardThreeTitle }} </h1>
                                <div ref="activeColorTwoMob" class="title-no">02</div>
                                    <a v-if="!mobTwo" @click="handleMobTwo" href="#mob-two-a" class="open-icon display-on"><div>+</div></a>
                                    <a v-if="mobTwo" class="opened-icon"><div><img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon"></div></a>
                                </div>
                        </div>

                        <div v-if="mobTwo" class="mobile-sec">
                            <div class="sec-card b1-card-eight-click-two carosel-card">
                                <div v-if="mailorflyer">
                                    <div><img src="../assets/photos/squares/ADDIE_MB_ALL.png" alt="flyer example" class="carosel-shot"></div>
                                    </div>
                                <!-- <div v-else>
                                        <div v-if="flyerone"><img src="../assets/photos/Beach-Bash.png" alt="flyer example" class="carosel-shot"></div>
                                        <div v-if="flyertwo"><img src="../assets/photos/Business_Flyer.png" alt="flyer example" class="carosel-shot"></div>
                                        <div v-if="flyerthree"><img src="../assets/photos/Missing_Dog.png" alt="flyer example" class="carosel-shot"></div>
                                        <div v-if="flyerfour"><img src="../assets/photos/Campus_Wines_Flyer.png" alt="flyer example" class="carosel-shot"></div>
                                    </div>
                                <a v-if="!mailorflyer" @click="handleFlyerClickRev" class="carosel-before-btn"><div>
                                        <span class="material-icons carosel-icon">navigate_before</span>
                                    </div></a>
                                <a v-if="!mailorflyer" @click="handleFlyerClickFwd" class="carosel-next-btn"><div>
                                        <span class="material-icons carosel-icon">navigate_next</span>
                                    </div></a>
                                <a v-if="mailorflyer" @click="chooseFlyer" class="chooser-btn"><div>
                                        <span class="material-icons chooser-icon">style</span>
                                    </div></a>
                                <a v-else @click="chooseMail" class="chooser-btn"><div>
                                        <span class="material-icons chooser-icon">mail</span>    
                                    </div></a> -->
                            </div>
                        </div>

                        <div class="mobile-sec">
                            <div class="sec-card-mob-desc b1-card-seven-click-two-mob-sec description-card">
                                <h1 v-if="mobTwo" class="title-h1-dk"> {{ descSubTitleTwo }} </h1>
                                <h1 v-else class="title-h1-dk"> {{ descSubTitleTwo}}... </h1>
                                <div class="scroll-mob">
                                    <div v-if="mobTwo" class="description-txt"> {{ descParaTextTwoOne }} </div>
                                    <div v-if="mobTwo" class="description-txt"> {{ descParaTextTwoTwo }} </div>
                                    <div v-if="mobTwo" class="description-txt"> {{ descParaTextTwoThree }} </div>
                                    <div v-if="mobTwo" class="description-txt"> {{ descParaTextTwoFour }} </div>
                                </div>
                                <a v-if="!mobTwo" @click="handleMobTwo" class="fly-btn"><div>
                                    <span class="material-icons fly-icon">expand_more</span>
                                </div></a>
                                <a v-if="mobTwo" @click="handleMobThree" href="#mob-three-a" class="fly-btn"><div>
                                    <span class="material-icons fly-icon">expand_more</span>
                                </div></a>
                            </div>
                            
                        </div>
                        <div v-if="mobTwo" class="mobile-sec">
                            <div class="sec-card b1-card-two-click-two"></div>
                        </div>
                        <div v-if="mobTwo" class="mobile-sec">
                            <div class="sec-card b1-card-three-click-two"></div>
                        </div>
                        
    
                        

                    </div>

                    <div class="sec-click-three">
                        
                        <div class="mobile-sec">
                            <div id="mob-three-a" class="sec-card b1-card-nine title">
                                <h1 class="title-h1"> {{ cardFourTitle }} </h1>
                                <div ref="activeColorThreeMob" class="title-no">03</div>
                                <a v-if="!mobThree" @click="handleMobThree" href="#mob-three-a" class="open-icon display-on"><div>+</div></a>
                                <a v-if="mobThree" class="opened-icon"><div><img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon"></div></a>
                            </div>
                        </div>

                        <div v-if="mobThree" class="mobile-sec">
                            <div class="sec-card b1-card-eight-click-three"></div>
                            <div class="sec-card b1-card-eleven-click-three"></div>
                        </div>

                        <div class="mobile-sec">
                            <div class="sec-card-mob-desc b1-card-seven-click-three-mob-sec description-card">
                                <h1 v-if="mobThree" class="title-h1-dk"> {{ descSubTitleThree }} </h1>
                                <h1 v-else class="title-h1-dk"> {{ descSubTitleThree }}... </h1>
                                <div class="scroll-mob">
                                    <div v-if="mobThree" class="description-txt"> {{ descParaTextThreeOne }} </div>
                                    <div v-if="mobThree" class="description-txt"> {{ descParaTextThreeTwo }} </div>
                                    <div v-if="mobThree" class="description-txt"> {{ descParaTextThreeThree }} </div>
                                    <div v-if="mobThree" class="description-txt"> {{ descParaTextThreeFour }} </div>
                                </div>
                                <a v-if="!mobThree" @click="handleMobThree" class="fly-btn"><div>
                                    <span class="material-icons fly-icon">expand_more</span>
                                </div></a>
                                <a v-if="mobThree" class="fly-btn"><div>
                                    <span class="material-icons fly-icon place-icon">place</span>
                                </div></a>
                            </div>
                            <div class="sec-card b1-card-two-click-three"></div>
                        </div>
                        <div v-if="mobThree" class="mobile-sec">
                            <div class="sec-card b1-card-twelve-click-three"></div>
                        </div>

                    </div>

                    <div class="sec-footer">
                        <div class="footer-card">
                            <div class="clear-card co-info">
                                <h1 class="footer-h1"> addie </h1>
                                <div>
                                    <div class="footer-text smpl-bold">Home Sweet Oxford</div>
                                    <a href="mailto:hello@getmyaddie.com"><div class="footer-text">hello@getmyaddie.com</div></a>
                                    <div class="footer-text smpl-top-space-md">elsewhere:</div>
                                    <div class="footer-text smpl-bold">@getmyaddie</div>
                                </div>
                            </div>
                        </div>

                        <div class="footer-card">
                            <div class="clear-card co-contact">
                                <h1 class="footer-h1-b dbl-right"> Contact Us </h1>
                                <div>
                                    <a href="mailto:hello@getmyaddie.com" class="main-nav-link nav-cta smpl-top-space-sm">What's On Your Mind</a>
                                    <a @click="handleShowTerms" href="#"><div class="footer-text dbl-right smpl-top-space-md">terms of service</div></a>
                                    <a @click="handleShowPrivacy" href="#"><div class="footer-text dbl-right">privacy policy</div></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </div>
        <div v-if="showTerms">
            <div class="reader">
                <h1>Legal</h1>
                <h2>Polices, Notices, Disclaimers and Terms Of Use of this Website</h2>
                <h3 class="smpl-bold">BY USING THIS WEBSITE, YOU AGREE TO ALL OF THE FOLLOWING POLICIES, NOTICES, DISCLAIMERS AND TERMS OF USE.</h3>
                <p>Addie LLC operates this website for the limited purpose of disseminating general information to assist the pursuit of qualified employment candidates and to provide general information to vendors and current clients. The website is not intended for any use which would be contrary to applicable law or regulation. In addition, this website is not intended to provide specific financial, accounting, tax or other advice and should not be relied upon for that purpose.This website is not an offer to, or solicitation of, any potential clients or investors. No material listed on this website is or should be construed as investment advice, nor is anything on this website an offer to sell, or a solicitation of an offer to buy, any security or other instrument. It is for information purposes only and is subject to change at any time without prior notice.</p>
                <h3 class="smpl-bold">DISCLAIMER</h3>
                <p>Addie LLC makes no representation or warranty as to the accuracy, adequacy or completeness of the information on this website and expressly disclaims liability for any errors or omissions therein or therefrom. In addition, Addie LLC makes no other representation or warranty, implied, express or statutory, in connection therewith, including (without limitation) fitness for a particular purpose, freedom from computer virus and non-infringement of third party rights.</p>
                <p>In no event will Addie LLC be liable for any damages, including (without limitation) direct or indirect, special, incidental or consequential damages, losses or expenses arising in connection with this website, or in connection with its use or attempted use, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if Addie LLC  is advised of the possibility of such damages, losses or expenses.</p>
                <h3 class="smpl-bold">TRADEMARKS</h3>
                <p>The Addie LLC logo and all associated elements, are the part of the intellectual property of this website and are subject to the copyright laws of the United States and other countries.</p>
                <h3 class="smpl-bold">BETA TERMS OF PARTICIPATION</h3>
                <p>Subject to the terms and conditions of this Agreement, Company grants Recipient a nonexclusive, nontransferable license to use the Addie iPhone App ("Service") for a period designated by the Addie, LLC (“Company”) for the purpose of testing and evaluating the Service.</p>
                <p>1. The Recipient agrees that nothing contained in this Agreement shall be construed as granting any ownership rights to any Confidential Information disclosed pursuant to this Agreement, or to any invention or any patent, copyright, trademark, or other intellectual property right. The Recipient shall not make, have made, use or sell for any purpose any product or other item using, incorporating or derived from any Confidential Information or the Service. The Recipient will not modify, reverse engineer, decompile, create other works from, or disassemble any software programs contained in the Confidential Information or the Service.</p>
                <p>2. This Service is a beta release offering and is not at the level of performance of a commercially available product offering. The Service may not operate correctly and may be substantially modified prior to first commercial release, or at Company's option may not be released commercially in the future.</p>
                <p>THE SERVICE AND DOCUMENTATION ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, AND COMPANY AND ITS LICENSORS DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. NO ORAL OR WRITTEN ADVICE OR CONSULTATION GIVEN BY COMPANY, ITS AGENTS OR EMPLOYEES WILL IN ANY WAY GIVE RISE TO A WARRANTY. THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF THE SERVICE REMAINS WITH RECIPIENT.</p>
                <p>3. COMPANY AND ITS LICENSORS SHALL NOT BE LIABLE FOR LOSS OF USE, LOST PROFIT, COST OF COVER, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO THE SERVICE OR THIS AGREEMENT, HOWEVER CAUSED AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) STRICT LIABILITY, OR OTHERWISE, EVEN IF SUCH PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </p>
                <p>IN NO EVENT WILL COMPANY'S AGGREGATE CUMULATIVE LIABILITY FOR ANY CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED $50.00 OR THE AMOUNT RECIPIENT ACTUALLY PAID COMPANY UNDER THIS AGREEMENT (IF ANY).</p>
                <p>4. The Recipient's obligations under this Agreement shall survive any termination of this agreement. This Agreement shall be governed by and construed in accordance with the laws of Mississippi. The Recipient hereby agrees that breach of this Agreement will cause Company irreparable damage for which recovery of damages would be inadequate, and that the Company shall therefore be entitled to obtain timely injunctive relief under this Agreement, as well as such further relief as may be granted by a court of competent jurisdiction. The Recipient will not assign or transfer any rights or obligations under this Agreement without the prior written consent of the Company.</p>
                <p>5. Submission of an email address by the Recipient to the Company and subsequent downloading of the Service from the Test Flight App shall act as an agreement by the Recipient to this Agreement. This agreement shall apply to third parties that may bring claims if the third parties use the Recipients email address and subsequent downloads on their own devices, or the Recipient downloads the Service onto a device not owned by the Recipient.</p>
                <p class="smpl-italic">We reserve the right to change these statements. Any revision to these statements and changes will be effective as of the date of posting.</p>
                <p class="smpl-italic">Last Updated: August 16, 2023</p>
                <a @click="handleShowLanding" href="#" class="main-nav-link nav-cta">Back to Addie</a>
            </div>
        </div>
        <div v-if="showPrivacy">
            <div class="reader">
                <h1>Legal</h1>
                <h3 class="smpl-bold">BY USING THIS WEBSITE, YOU AGREE TO ALL OF THE FOLLOWING POLICIES, NOTICES, DISCLAIMERS AND TERMS OF USE.</h3>
                <h3 class="smpl-bold">SUBMISSIONS</h3>
                <p>Please do not send any information to Addie LLC that constitutes a trade secret or is confidential or proprietary. Addie LLC  cannot agree to obligations of confidentiality or non-disclosure with regard to information submitted to it. By submitting information or materials to Addie LLC, you or anyone acting on your behalf, agree that any such information or materials will not be considered confidential or proprietary.</p>
                <h3 class="smpl-bold">SECURITY</h3>
                <p>This Website is not designed to provide security against unauthorized interception or use of information you may provide through your use of it. Do not transmit confidential or sensitive information to us over this Website.</p>
                <h3 class="smpl-bold">COPYRIGHT</h3>
                <p>This Website was created by Addie LLC, which owns the copyright interest therein. Use and/or reproduction of any portion of this Website is limited as described in the Permitted Use section of this page, and is subject to the Disclaimer and other cautions appearing on this page, all as may be updated or changed at any time.</p>
                <h3 class="smpl-bold">PERMITTED USE</h3>
                <p>You are granted permission to view, store, copy and print any of the pages of this Website. You are also granted permission to distribute any one or more of these pages, provided you do so without making any alteration and that this page is included with the other page(s) you distribute.</p>
                <p class="smpl-italic">Last Updated: August 16, 2023</p>
                <p class="smpl-italic">We reserve the right to change these statements. Any revision to these statements and changes will be effective as of the date of posting.</p>
                <a @click="handleShowLanding" href="#" class="main-nav-link nav-cta">Back to Addie</a>

            </div>
        </div>

    </main>
    
</template>

<script>

import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { projectFirestore, opt } from '../firebase/config'


export default {

    name: "Home",

    components: { VueGoogleAutocomplete },
    
    setup() {

        const router = useRouter()

        const showLanding = ref(true)
        const showTerms = ref(false)
        const showPrivacy = ref(false)

        const activeColorOne = ref(null)
        const activeColorTwo = ref(null)
        const activeColorThree = ref(null)

        const activeColorOneMob = ref(null)
        const activeColorTwoMob = ref(null)
        const activeColorThreeMob = ref(null)

        const iconOne = ref(false)
        const iconOneBee = ref(true)
        const iconTwo = ref(true)
        const iconThree = ref(true)

        const mobOne = ref(false)
        const mobBee = ref(false)
        const mobTwo = ref(false)
        const mobThree = ref(false)

        const mailorflyer = ref(true)
        const flyerone = ref(true)
        const flyertwo = ref(false)
        const flyerthree = ref(false)
        const flyerfour = ref(false)

        const cardOneTitle = ref("Your Home Dashboard")
        const cardTwoTitle = ref("Your Home Scorecard")
        const cardThreeTitle = ref("Your Mailbox, Your Terms")
        const cardFourTitle = ref("Your Addie Book, Updated")
        
        const descSubTitleOne = ref("Elevate Your Home's Potential")
        const descSubTitleBee = ref("Your Vision, Your Home, Your Terms")
        const descSubTitleTwo = ref("Get Paid to Open Your Mail")
        const descSubTitleThree = ref("Stay in Touch, The Easy Way")
        
        const descParaText = ref("")
        
        const descParaTextOneOne = ref("Are you a homeowner yearning to transform your living spaces or a skilled agent with a keen eye for exceptional properties? Maybe you're an innovative interior designer ready to breathe life into blank canvases or a photographer who can capture the magic?")
        const descParaTextOneTwo = ref("What is Addie? Addie is a dashboard for your home, whether you own that home, manage that home, or are helping design or sell that home.")
        const descParaTextOneThree = ref("Addie bridges the information gap to create a homebase for organization, creativity, expertise, and vision. Addie is seamlessly designed to empower homeowners, agents, creators, and services in making your house a home and your most valuable asset. Addie redefines the way properties evolve into cherished spaces.")
        const descParaTextOneFour = ref("However you use Addie, use Addie to make your house a home.")

        const descParaTextBeeOne = ref("The future of home sales is at the corner of empowerment and innovation.")
        const descParaTextBeeTwo = ref("Addie can seamlessly connect local homeowners with astute agents. Or, it can put you in the driver's seat of your property's destiny.")
        const descParaTextBeeThree = ref("Your Addie dashboard gives you the technology to take charge like never before. Let a network of skilled agents, managers, or designers assist in your home sales after you review their proposed rates and sales history.")
        const descParaTextBeeFour = ref("Addie empowers you to articulate your dreams and maximize your assets by matching you with the perfect agent and interior designer duo. Elevate your home with Addie, ensuring every wall, corner, and detail is a reflection of you.")

        const descParaTextTwoOne = ref("Connect with your neighbors, your neighborhood, and your community in a whole new way.")
        const descParaTextTwoTwo = ref("Need a cup of sugar? No problem! Send a Note to a neighbor.")
        const descParaTextTwoThree = ref("Tired of businesses using your data and not getting anything in return? On Addie, you control who pays you for your attention.")
        const descParaTextTwoFour = ref("Businesses buy stamps and send you deals. Set who has to pay and who doesn't - then read your Notes, check out Flyers, and get paid.")

        const descParaTextThreeOne = ref("Once you add your friends and family to your Addie Address Book, you will never lose touch again.")
        const descParaTextThreeTwo = ref("No more tracking down who moved or where they went to. Invite friends and family to your Address Book and if they move, so does the contact in your Address Book.")
        const descParaTextThreeThree = ref("Easily collect addresses with our URL generator, even if they are not on Addie.")
        const descParaTextThreeFour = ref("When you are ready to send out Christmas Cards, use our CSV exporter for use in services like Minted.")

        // Lost a dog or have a community potluck? Send a note to the whole neighborhood.
        
        const handleFlyerClickFwd = () => {

            if (flyerone.value === true) {
                
                flyerone.value = false
                flyertwo.value = true
                flyerthree.value = false
                flyerfour.value = false

            } else if (flyertwo.value === true) {
            
                flyerone.value = false
                flyertwo.value = false
                flyerthree.value = true
                flyerfour.value = false
                

            } else if (flyerthree.value === true) {
            
                flyerone.value = false
                flyertwo.value = false
                flyerthree.value = false
                flyerfour.value = true

            } else if (flyerfour.value === true) {

                flyerone.value = true
                flyertwo.value = false
                flyerthree.value = false
                flyerfour.value = false
            }
            
        }

        const handleFlyerClickRev = () => {
            
            if(flyerone.value === true){

                flyerone.value = false
                flyertwo.value = false
                flyerthree.value = false
                flyerfour.value = true
            }
            if(flyertwo.value === true){

                flyerone.value = true
                flyertwo.value = false
                flyerthree.value = false
                flyerfour.value = false
            }
            if(flyerthree.value === true){

                flyerone.value = false
                flyertwo.value = true
                flyerthree.value = false
                flyerfour.value = false
            }
            if(flyerfour.value === true){

                flyerone.value = false
                flyertwo.value = false
                flyerthree.value = true
                flyerfour.value = false
            }
            
        }

        const handleClickOne = () => {
            
            iconOne.value = false
            iconOneBee.value = true
            iconTwo.value = true
            iconThree.value = true

            activeColorOne.value.classList.add("active")
            activeColorTwo.value.classList.remove("active")
            activeColorThree.value.classList.remove("active")
        }

        const handleClickOneBee = () => {
            
            iconOne.value = true
            iconOneBee.value = false
            iconTwo.value = true
            iconThree.value = true

            activeColorOne.value.classList.add("active")
            activeColorTwo.value.classList.remove("active")
            activeColorThree.value.classList.remove("active")
        }

        const handleClickTwo = () => {
            
            iconOne.value = true
            iconOneBee.value = true
            iconTwo.value = false
            iconThree.value = true

            activeColorOne.value.classList.remove("active")
            activeColorTwo.value.classList.add("active")
            activeColorThree.value.classList.remove("active")
        }

        const handleClickThree = () => {
            
            iconOne.value = true
            iconOneBee.value = true
            iconTwo.value = true    
            iconThree.value = false

            activeColorOne.value.classList.remove("active")
            activeColorTwo.value.classList.remove("active")
            activeColorThree.value.classList.add("active")
        }

        const handleMobOne = () => {
            
            mobOne.value = true
            mobBee.value = false
            mobTwo.value = false
            mobThree.value = false

            activeColorOneMob.value.classList.add("active")
            activeColorTwoMob.value.classList.remove("active")
            activeColorThreeMob.value.classList.remove("active")
        }

        const handleMobBee = () => {
            
            mobOne.value = false
            mobBee.value = true
            mobTwo.value = false
            mobThree.value = false

            activeColorOneMob.value.classList.add("active")
            activeColorTwoMob.value.classList.remove("active")
            activeColorThreeMob.value.classList.remove("active")
        }

        const handleMobTwo = () => {
            
            mobOne.value = false
            mobBee.value = false
            mobTwo.value = true
            mobThree.value = false

            activeColorOneMob.value.classList.remove("active")
            activeColorTwoMob.value.classList.add("active")
            activeColorThreeMob.value.classList.remove("active")
        }

        const handleMobThree = () => {
            
            mobOne.value = false
            mobBee.value = false
            mobTwo.value = false
            mobThree.value = true

            activeColorOneMob.value.classList.remove("active")
            activeColorTwoMob.value.classList.remove("active")
            activeColorThreeMob.value.classList.add("active")
        }

        const handleClickGetApp = async (event) => {
            event.preventDefault();
            console.log('Go get it!');
            window.location.href = 'https://apps.apple.com/us/app/addie-dashboard/id6449395138';
        }
        
        const handleShowTerms = () => {
            showLanding.value = false;
            showTerms.value = true;
        }  
        
        const handleShowPrivacy = () => {
            showLanding.value = false;
            showPrivacy.value = true;
        }  

        const handleShowLanding = () => {
            showPrivacy.value = false;
            showTerms.value = false;
            showLanding.value = true;
        }  

        const chooseMail = () => {
            mailorflyer.value = true
        }

        const chooseFlyer = () => {
            mailorflyer.value = false
        }

        const clientIp = ref('')
        const claimedio = ref(false)

        onMounted(() => {
            fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(async response => {
                clientIp.value = response.ip;

                const ipclaims = []
                const users = await projectFirestore.collection('online').doc('addieurls').get()

                if(users.exists){

                    users.data()['users'].forEach(claim => {
                        if(claim === clientIp.value){
                            claimedio.value = true
                        }
                    })
                }
            })
        })


        return { 
            cardOneTitle, 
            cardTwoTitle, 
            cardThreeTitle, 
            cardFourTitle, 
            descParaText, 
            descSubTitleOne, 
            descSubTitleBee, 
            descSubTitleTwo, 
            descSubTitleThree,
            descParaTextOneOne, 
            descParaTextOneTwo, 
            descParaTextOneThree, 
            descParaTextOneFour,  
            descParaTextBeeOne, 
            descParaTextBeeTwo, 
            descParaTextBeeThree, 
            descParaTextBeeFour,
            descParaTextTwoOne, 
            descParaTextTwoTwo, 
            descParaTextTwoThree, 
            descParaTextTwoFour, 
            descParaTextThreeOne, 
            descParaTextThreeTwo, 
            descParaTextThreeThree, 
            descParaTextThreeFour,
            mobOne, 
            mobBee, 
            mobTwo, 
            mobThree,
            iconOne, 
            iconOneBee, 
            iconTwo, 
            iconThree, 
            mailorflyer, 
            activeColorOne, 
            activeColorTwo, 
            activeColorThree, 
            activeColorOneMob, 
            activeColorTwoMob, 
            activeColorThreeMob,
            flyerone, 
            flyertwo, 
            flyerthree, 
            flyerfour,
            showLanding, 
            showTerms, 
            showPrivacy,
            handleClickOne, 
            handleClickOneBee, 
            handleClickTwo, 
            handleClickThree, 
            handleClickGetApp, 
            chooseMail, 
            chooseFlyer,
            handleMobOne, 
            handleMobBee, 
            handleMobTwo, 
            handleMobThree,
            handleFlyerClickRev, 
            handleFlyerClickFwd,
            handleShowTerms, 
            handleShowPrivacy, 
            handleShowLanding}                         
    
    }, 

}
</script>

<style>

</style>