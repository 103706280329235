<template>

   <header>
        <div class="hb-header">
            <a href="/">
                <h1 class="fly-h1">addie</h1>
            </a>
            <ul class="main-nav-list">
                <li><a class="main-nav-link" href="/">How it works</a></li>
                
                <li><a @click="visitAppStore" href="#" class="main-nav-link nav-cta">Get Addie App</a></li>
            </ul>
        </div>
        
    </header>

    <main>
        <div class="several-column">
            <section class="top-spacer">.</section>
            <section class="hb-home">

                <!-- 3w R1 C1-3 -->
                <div class="hb-hero-info fly-hero">
                    <div class="title-no active fly-no"><img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon"></div>
                    
                </div>
          
                <!-- 3w R1 C1 -->
                <div ref="activeTitleOne" class="sec-card b1-card-four title fly-one">
                        <h1 class="title-h1">{{ cardOneTitle }}</h1>
                        <div v-if="iconOne" class="title-no active">01</div>
                        <div v-else class="title-no">01</div>
                        <a @click="handleClickOne" class="open-icon"><div>+</div></a>
                    </div>

                <!-- 3w R1+2 C2 -->
                <div class="sec-card b1-card-eight-click-two-form collection-card fly-viewer">

                    <div v-if="!didThingIo" class="collection-addy">

                            <h1 class="addie-h1 smpl-btm-space-md"> addie </h1>

                            <div v-if="!fetchio"> 
                                <h1  class="collection-h3 smpl-bold smpl-btm-space-md smpl-btm-space-md">Expired Collection Link</h1> 
                                <div class="description-txt white-txt smpl-top-space-md smpl-btm-space-md">🏠 This always-up-to-date collection link has expired. You can still get Addie and have your home up and running in no time! 💫</div>
                                <a @click="visitAppStore" class="web-chooser smpl-top-space-sm"><div>Addie for iPhone</div></a>
                            </div>

                            <div v-if="fetchio">
                                <div class="collection-h3 smpl-top-space-md"> A request from: </div>
                                <h1  class="collection-h2 smpl-top-space-sm">{{ collectionName }}</h1>
                                <h1  class="collection-h3 smpl-bold">{{ collectionAddy }}</h1>
                                <div class="collection-h3 smpl-btm-space-md"> {{ collectionCitySt }} </div>
                                <div class="description-txt white-txt smpl-top-space-md smpl-btm-space-md">🏠 Kindly submit your current address. If you join later, you will always have up-to-date addresses! 💫 </div>

                                <a @click="toggleModal" class="web-chooser smpl-top-space-md"><div>Share Address</div></a>
                            </div>
                        </div>

                        <div v-if="didThingIo && fetchio" class="collection-addy">
                            <div class="collection-h2 smpl-top-space-md "> Thanks for sharing! </div>

                            <div class="description-txt white-txt smpl-top-space-md">🏠 Download the Addie app and grab your address with the access code to have your home up and running in no time! 💫 </div>
                            <h1  class="collection-h3 smpl-bold">{{ collectionAddy }}</h1>
                
                            <a @click="visitAppStore" class="web-chooser smpl-top-space-sm"><div>Get Addie for iPhone</div></a>
                        
                        </div>

                    </div>

                <!-- 3w R1 C3 -->
                <div ref="activeTitleBee" class="sec-card b1-card-five title fly-bee">
                        <h1 class="title-h1">{{ cardTwoTitle }}</h1>
                        <a @click="handleClickOneBee" class="open-icon"><div>+</div></a>
                    </div>
                

                <!-- 3w R2 C1 -->
                <div ref="activeTitleTwo" class="sec-card b1-card-six title fly-two">
                        <h1 class="title-h1">{{ cardThreeTitle }}</h1>
                        <div v-if="iconTwo" class="title-no active">02</div>
                        <div v-else class="title-no">02</div>
                        <a @click="handleClickTwo" class="open-icon"><div>+</div></a>
                    </div>

                <!-- 3w R2 C3 -->
                <div ref="activeTitleThree" class="sec-card b1-card-nine title fly-three">
                       <h1 class="title-h1">{{ cardFourTitle }}</h1>
                        <div v-if="iconThree" class="title-no active">03</div>
                        <div v-else class="title-no">03</div>
                        <a @click="handleClickThree" class="open-icon"><div>+</div></a>
                    </div>

                <!-- Depends on Card Reading -->
                <div ref="descPlace" class="sec-card addy1-card-seven-click-one description-card fly-d">
                        <div class="scroll"> 
                            <div v-if="iconOne" class="description-txt"> {{ descParaTextOneOne }} </div>
                            <div v-if="iconOneBee" class="description-txt"> {{ descParaTextBeeOne }} </div>
                            <div v-if="iconTwo" class="description-txt"> {{ descParaTextTwoOne }} </div>
                            <div v-if="iconThree" class="description-txt"> {{ descParaTextThreeOne }} </div>
                            <div> <a href="/"><div class="description-txt smpl-bold">learn more ></div></a> </div>
                        </div>
                        <a @click="handleDescOff" class="fly-btn"><div>
                                <span class="material-icons fly-icon">close</span>
                            </div></a>
                    </div>

                <!-- 3w Footer -->
                <div class="footer-card">
                    <div class="clear-card co-info">
                        <h1 class="footer-h1"> addie </h1>
                        <div>
                            <div class="footer-text smpl-bold">Home Sweet Oxford</div>
                            <a href="mailto:hello@getmyaddie.com"><div class="footer-text">hello@getmyaddie.com</div></a>
                            <div class="footer-text smpl-top-space-md">elsewhere:</div>
                            <div class="footer-text smpl-bold">@getmyaddie</div>
                        </div>
                    </div>

                    <div class="clear-card co-contact">
                        <h1 class="footer-h1-b dbl-right"> Contact Us </h1>
                        <div>
                            <a @click="handleClickContact" href="#contact" class="main-nav-link nav-cta smpl-top-space-sm">What's On Your Mind</a>
                            <a @click="handleShowTerms" href="/notices"><div class="footer-text dbl-right smpl-top-space-md">terms of service</div></a>
                            <a @click="handleShowPrivacy" href="/notices"><div class="footer-text dbl-right">privacy policy</div></a>
                        </div>
                    </div>
                </div>

            </section>
        </div>

        <div class="mobile-column">
            <section class="top-spacer">.</section>
            <section class="hb-home">

                <!-- 1w R1 -->
                <div class="hb-hero-info fly-hero">
                    <div class="title-no active fly-no"><img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon"></div>
                    
                </div>

                <!-- 1w R2 -->
                <div class="sec-card-mob-desc b1-card-eight-click-two-mob-sec-desc collection-card fly-viewer">
                        
                        <h1 class="addie-h1 smpl-top-space-md"> addie </h1>
                    
                        <div v-if="!didThingIo" class="collection-addy">
                            <div v-if="!fetchio"> 
                                <h1  class="collection-h3 smpl-bold smpl-btm-space-md smpl-btm-space-md">Expired Collection Link</h1> 
                                <div class="description-txt white-txt smpl-top-space-md smpl-btm-space-md">🏠 This always-up-to-date collection link has expired. You can still get Addie and have your home up and running in no time! 💫</div>
                                <a @click="visitAppStore" class="web-chooser smpl-top-space-sm"><div>Addie for iPhone</div></a>
                            </div>

                            <div v-if="fetchio">
                                <div class="collection-h2"> A request from: </div>
                                <h1  class="collection-h1">{{ collectionName }}</h1>
                                <h1  class="collection-h2">{{ collectionAddy }}</h1>
                                <div class="collection-h3"> {{ collectionCitySt }} </div>
                                <div class="description-txt white-txt smpl-top-space-md">🏠 Kindly submit your current address. If you join later, you will always have up-to-date addresses! 💫 </div>
                                
                                <a @click="toggleModal" class="collection-chooser"><div><a>Submit Your Address</a></div></a>
                            </div>
                        </div>

                        <div v-if="didThingIo" class="collection-addy">
                            <div class="collection-h2 smpl-top-space-md "> Thanks for sharing! </div>

                            <div class="description-txt white-txt smpl-top-space-md">🏠 Download the Addie app and grab your address with the access code to have your home up and running in no time! 💫 </div>
                            <h1  class="collection-h3 smpl-bold">{{ collectionAddy }}</h1>
                
                            <a @click="visitAppStore" class="web-chooser smpl-top-space-sm"><div>Addie for iPhone</div></a>
                        
                        </div>
                        
                    </div>

                <!-- 1w R3 -->
                <div ref="activeTitleMobOne" class="sec-card b1-card-four title fly-one">
                        <h1 class="title-h1">{{ cardOneTitle }}</h1>
                        <div v-if="iconOne" class="title-no active">01</div>
                        <div v-else class="title-no">01</div>
                        <a @click="handleMobOne" class="open-icon"><div>+</div></a>
                    </div>
                
                <!-- 1w R4 -->
                <div id="to-bee" ref="activeTitleMobBee" class="sec-card b1-card-five title fly-bee">
                        <h1 class="title-h1">{{ cardTwoTitle }}</h1>
                        <a @click="handleMobBee" class="open-icon"><div>+</div></a>
                    </div>

                <!-- 1w R5 -->
                <div id="to-two" ref="activeTitleMobTwo" class="sec-card b1-card-six title fly-two">
                        <h1 class="title-h1">{{ cardThreeTitle }}</h1>
                        <div v-if="iconTwo" class="title-no active">02</div>
                        <div v-else class="title-no">02</div>
                        <a @click="handleMobTwo" class="open-icon"><div>+</div></a>
                    </div>

                <!-- 3w R2 C3 -->
                <div id="to-three" ref="activeTitleMobThree" class="sec-card b1-card-nine title fly-three">
                       <h1 class="title-h1">{{ cardFourTitle }}</h1>
                        <div v-if="iconThree" class="title-no active">03</div>
                        <div v-else class="title-no">03</div>
                        <a @click="handleMobThree" class="open-icon"><div>+</div></a>
                    </div>
                
                <!-- Depends on Card Reading -->
                
                <div ref="descMobPlace" class="sec-card b1-card-seven-click-one-mob-desc description-card fly-d">
                        <div v-if="iconOne" class="description-txt"> {{ descParaTextOneOne }} </div>
                        <div v-if="iconOneBee" class="description-txt"> {{ descParaTextBeeOne }} </div>
                        <div v-if="iconTwo" class="description-txt"> {{ descParaTextTwoOne }} </div>
                        <div v-if="iconThree" class="description-txt"> {{ descParaTextThreeOne }} </div>
                        <!-- <a href="www.getmyaddie.com"><div class="footer-text">learn more ></div></a> -->
                        <a v-if="iconOne" @click="handleMobDescOff" href="#to-bee" class="fly-btn"><div>
                                <span class="material-icons fly-icon">expand_more</span>
                            </div></a>
                        <a v-if="iconOneBee" @click="handleMobDescOff" href="#to-two" class="fly-btn"><div>
                                <span class="material-icons fly-icon">expand_more</span>
                            </div></a>
                        <a v-if="iconTwo" @click="handleMobDescOff" href="#to-three" class="fly-btn"><div>
                                <span class="material-icons fly-icon">expand_more</span>
                            </div></a>
                        <a v-if="iconThree" @click="handleMobDescOff" href="#" class="fly-btn"><div>
                                <span class="material-icons fly-icon place-icon">place</span>
                            </div></a>
                    </div>

                <!-- 1w Footer 1 -->
                <div class="footer-card">
                    <div class="clear-card co-info">
                        <h1 class="footer-h1"> addie </h1>
                        <div>
                            <div class="footer-text smpl-bold">Home Sweet Oxford</div>
                            <a href="mailto:hello@getmyaddie.com"><div class="footer-text">hello@getmyaddie.com</div></a>
                            <div class="footer-text smpl-top-space-md">elsewhere:</div>
                            <div class="footer-text smpl-bold">@getmyaddie</div>
                        </div>
                    </div>
                </div>

                <div class="footer-card">
                    <div class="clear-card co-contact">
                        <h1 class="footer-h1-b dbl-right"> Contact Us </h1>
                        <div>
                            <a @click="handleClickContact" href="#contact" class="main-nav-link nav-cta smpl-top-space-sm">What's On Your Mind</a>
                            <a @click="handleShowTerms" href="/notices"><div class="footer-text dbl-right smpl-top-space-md">terms of service</div></a>
                            <a @click="handleShowPrivacy" href="/notices"><div class="footer-text dbl-right">privacy policy</div></a>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    </main>

    <div v-if="showModal">
    <Modal type="submit" :name="collectionName" :saveid="addieid" :address="collectionAddy" :code="addressId" :delid="fetchid" @close="toggleModal" @complete="completeModal" /> 
    </div> 

</template>

<script>

import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Modal from '../components/Modal.vue';
import { adbFunctions } from '../models/functions.js';
import { projectFirestore, opt } from '../firebase/config'

export default {

name: "Address-Collector",
  
components: { Modal, adbFunctions, projectFirestore, useRoute, useRouter },
  
   setup() {

        const showModal = ref(false)
        const didThingIo = ref(false)
        
        const toggleModal = () => { 
            showModal.value = !showModal.value; 
            }

        const completeModal = () => {
            didThingIo.value = true
            showModal.value = !showModal.value; 
        }

        const visitAppStore = (event) => {
            event.preventDefault();
            console.log('Go get it!');
            window.location.href = 'https://apps.apple.com/us/app/addie-dashboard/id6449395138';
        }

        const router = useRouter()
        const route = useRoute()

        const addieid = ref('')
        const addyio = ref(false)
        const fetchio = ref(false)
        const claimedio = ref(false)

        const isEmpty = ref(true)
        const isPending = ref(false)
        const error = ref(null)

        const collectionAddy = ref('')
        const collectionCitySt = ref('')
        const collectionName = ref('')

        const collectionComplete = ref(false)

        const showLanding = ref(true)
        const showTerms = ref(false)
        const showPrivacy = ref(false)

        const descPlace = ref(null)
        const descMobPlace = ref(null)

        const activeTitleOne = ref(null)
        const activeTitleBee = ref(null)
        const activeTitleTwo = ref(null)
        const activeTitleThree = ref(null)

        const activeTitleMobOne = ref(null)
        const activeTitleMobBee = ref(null)
        const activeTitleMobTwo = ref(null)
        const activeTitleMobThree = ref(null)

        const iconOne = ref(false)
        const iconOneBee = ref(false)
        const iconTwo = ref(false)
        const iconThree = ref(false)

        const mailorflyer = ref(true)
        const showSignup = ref(false)

        const cardOneTitle = ref("your home dashboard")
        const cardTwoTitle = ref("build your addie score")
        const cardThreeTitle = ref("your mailbox, your terms")
        const cardFourTitle = ref("your address book, updated")
        
        const descSubTitleOne = ref("Your home at your fingertips")
        const descSubTitleBee = ref("Sell your home fee-free")
        const descSubTitleTwo = ref("Get paid to open your mail")
        const descSubTitleThree = ref("People move; don't lose touch")

        const descParaTextOneOne = ref("Addie is a dashboard for your home. Addie is a place where you can schedule and pay for maintenance for your home, inside and out. Whether managing tenants or just your family, you can do it all in Addie.")
        const descParaTextBeeOne = ref("As you perform everyday maintenance on your home using Addie, you build your Addie Score. Once you reach a 100/100 Addie Score, you will be able to value and sell your home fee-free using the Addie Sales Manager.")
        const descParaTextTwoOne = ref("Connect with your neighbors and your community in a whole new way. On Addie, you have control of who has to pay YOU for your attention. You set who pays. Then when you read your Notes and Flyers, you get paid.")
        const descParaTextThreeOne = ref("Once you add your family and friends to your Addie Address Book, you will never lose touch. No more tracking down who moved or where they landed. When they move, so does their contact in your Address Book.")
        
        const claimio = ref(false)
        const claimStr = ref('')

        const handleDescOff = () => {

            activeTitleOne.value.classList.remove("fly-h")
            activeTitleBee.value.classList.remove("fly-h")
            activeTitleTwo.value.classList.remove("fly-h")
            activeTitleThree.value.classList.remove("fly-h")

            descPlace.value.classList.remove("fly-d-show-one")
            descPlace.value.classList.remove("fly-d-show-two")
            descPlace.value.classList.remove("fly-d-show-three")
            descPlace.value.classList.remove("fly-d-show-four")

            descPlace.value.classList.add("fly-d")

        }

        const handleClickOne = () => {


            activeTitleBee.value.classList.remove("fly-h")
            activeTitleTwo.value.classList.remove("fly-h")
            activeTitleThree.value.classList.remove("fly-h")

            descPlace.value.classList.remove("fly-d")
            descPlace.value.classList.remove("fly-d-show-two")
            descPlace.value.classList.remove("fly-d-show-three")
            descPlace.value.classList.remove("fly-d-show-four")

            iconOneBee.value = false;
            iconTwo.value = false;  
            iconThree.value = false;    
            
            iconOne.value = true;
            activeTitleOne.value.classList.add("fly-h")
            descPlace.value.classList.add("fly-d-show-one")

        }

        const handleClickOneBee = () => {
            
            activeTitleOne.value.classList.remove("fly-h")
            activeTitleTwo.value.classList.remove("fly-h")
            activeTitleThree.value.classList.remove("fly-h")

            descPlace.value.classList.remove("fly-d")
            descPlace.value.classList.remove("fly-d-show-one")
            descPlace.value.classList.remove("fly-d-show-three")
            descPlace.value.classList.remove("fly-d-show-four")

            iconOne.value = false;
            iconTwo.value = false;  
            iconThree.value = false;          

            iconOneBee.value = true;
            activeTitleBee.value.classList.add("fly-h")
            descPlace.value.classList.add("fly-d-show-two")
        
        }

        const handleClickTwo = () => {
            
            activeTitleOne.value.classList.remove("fly-h")
            activeTitleBee.value.classList.remove("fly-h")
            activeTitleThree.value.classList.remove("fly-h")

            descPlace.value.classList.remove("fly-d")
            descPlace.value.classList.remove("fly-d-show-one")
            descPlace.value.classList.remove("fly-d-show-two")
            descPlace.value.classList.remove("fly-d-show-four")

            iconOne.value = false;
            iconOneBee.value = false;  
            iconThree.value = false;    

            iconTwo.value = true;
            activeTitleTwo.value.classList.add("fly-h")
            descPlace.value.classList.add("fly-d-show-three")

        }

        const handleClickThree = () => {
            
            activeTitleOne.value.classList.remove("fly-h")
            activeTitleBee.value.classList.remove("fly-h")
            activeTitleTwo.value.classList.remove("fly-h")

            descPlace.value.classList.remove("fly-d")
            descPlace.value.classList.remove("fly-d-show-one")
            descPlace.value.classList.remove("fly-d-show-two")
            descPlace.value.classList.remove("fly-d-show-three")

            iconOne.value = false;
            iconOneBee.value = false; 
            iconTwo.value = false;  

            iconThree.value = true;
            activeTitleThree.value.classList.add("fly-h")
            descPlace.value.classList.add("fly-d-show-four")
 
        }

        const handleMobDescOff = () => {

            activeTitleMobOne.value.classList.remove("fly-h")
            activeTitleMobBee.value.classList.remove("fly-h")
            activeTitleMobTwo.value.classList.remove("fly-h")
            activeTitleMobThree.value.classList.remove("fly-h")

            descMobPlace.value.classList.remove("fly-d-show-one")
            descMobPlace.value.classList.remove("fly-d-show-two")
            descMobPlace.value.classList.remove("fly-d-show-three")
            descMobPlace.value.classList.remove("fly-d-show-four")

            descMobPlace.value.classList.add("fly-d")

        }

        const handleMobOne = () => {
            
            activeTitleMobBee.value.classList.remove("fly-h")
            activeTitleMobTwo.value.classList.remove("fly-h")
            activeTitleMobThree.value.classList.remove("fly-h")

            descMobPlace.value.classList.remove("fly-d")
            descMobPlace.value.classList.remove("fly-d-show-two")
            descMobPlace.value.classList.remove("fly-d-show-three")
            descMobPlace.value.classList.remove("fly-d-show-four")

            iconOneBee.value = false;
            iconTwo.value = false;  
            iconThree.value = false;    
            
            iconOne.value = true;
            activeTitleMobOne.value.classList.add("fly-h")
            descMobPlace.value.classList.add("fly-d-show-one")

        }

        const handleMobBee = () => {
            
            activeTitleMobOne.value.classList.remove("fly-h")
            activeTitleMobTwo.value.classList.remove("fly-h")
            activeTitleMobThree.value.classList.remove("fly-h")

            descMobPlace.value.classList.remove("fly-d")
            descMobPlace.value.classList.remove("fly-d-show-one")
            descMobPlace.value.classList.remove("fly-d-show-three")
            descMobPlace.value.classList.remove("fly-d-show-four")

            iconOne.value = false;
            iconTwo.value = false;  
            iconThree.value = false;    
            
            iconOneBee.value = true;
            activeTitleMobBee.value.classList.add("fly-h")
            descMobPlace.value.classList.add("fly-d-show-two")

        }

        const handleMobTwo = () => {
            
            activeTitleMobOne.value.classList.remove("fly-h")
            activeTitleMobBee.value.classList.remove("fly-h")
            activeTitleMobThree.value.classList.remove("fly-h")

            descMobPlace.value.classList.remove("fly-d")
            descMobPlace.value.classList.remove("fly-d-show-one")
            descMobPlace.value.classList.remove("fly-d-show-two")
            descMobPlace.value.classList.remove("fly-d-show-four")

            iconOne.value = false;
            iconOneBee.value = false;  
            iconThree.value = false;    
            
            iconTwo.value = true;
            activeTitleMobTwo.value.classList.add("fly-h")
            descMobPlace.value.classList.add("fly-d-show-three")

        }

        const handleMobThree = () => {
            
            activeTitleMobOne.value.classList.remove("fly-h")
            activeTitleMobBee.value.classList.remove("fly-h")
            activeTitleMobTwo.value.classList.remove("fly-h")

            descMobPlace.value.classList.remove("fly-d")
            descMobPlace.value.classList.remove("fly-d-show-one")
            descMobPlace.value.classList.remove("fly-d-show-two")
            descMobPlace.value.classList.remove("fly-d-show-three")

            iconOne.value = false;
            iconOneBee.value = false;  
            iconTwo.value = false;    
            
            iconThree.value = true;
            activeTitleMobThree.value.classList.add("fly-h")
            descMobPlace.value.classList.add("fly-d-show-four")

        }

        const handleClickCollection = () => {
            // Open Modal
        }
        
        const handleShowTerms = () => {
            showLanding.value = false;
            showTerms.value = true;
        }  
        
        const handleShowPrivacy = () => {
            showLanding.value = false;
            showPrivacy.value = true;
        }  

        const handleShowLanding = () => {
            showPrivacy.value = false;
            showTerms.value = false;
            showLanding.value = true;
        }  

        const chooseMail = () => {
            mailorflyer.value = true
        }

        const chooseFlyer = () => {
            mailorflyer.value = false
        } 

        const { getAddieId, getAppleApp } = adbFunctions();

        onMounted( async () => {

            await fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(async response => {
                
                const clientIp = response.ip;

                const ipclaims = []
                const users = await projectFirestore.collection('online').doc('addieurls').get()

                if(users.exists){

                    users.data()['users'].forEach(claim => {
                        if(claim === clientIp.value){
                            claimedio.value = true
                        }
                    })
                }
            })

            const saveid = route.query.addy

            const addieFetch = await getAddieId(saveid)

            const flyerror = addieFetch.flyerror;

            addieid.value = saveid
            collectionAddy.value = addieFetch.collectionAddy;
            collectionCitySt.value = addieFetch.collectionCitySt;
            collectionName.value = addieFetch.collectionName;
            fetchio.value = addieFetch.fetchio;
            addyio.value = addieFetch.addieio;

        })

        return { 

            showModal,
            didThingIo,
            toggleModal,

            addieid, 
            addyio, 
            fetchio,
            claimedio, 
            
            isEmpty, 
            isPending, 
            error, 

            collectionAddy, 
            collectionCitySt, 
            collectionName, 
            
            collectionComplete,

            showLanding, 
            showTerms, 
            showPrivacy,

            descPlace, 
            descMobPlace, 

            activeTitleOne, 
            activeTitleBee, 
            activeTitleTwo, 
            activeTitleThree,
            
            activeTitleMobOne, 
            activeTitleMobBee, 
            activeTitleMobTwo, 
            activeTitleMobThree,

            iconOne, 
            iconOneBee, 
            iconTwo, 
            iconThree, 
            
            mailorflyer, 
            showSignup, 

            cardOneTitle, 
            cardTwoTitle, 
            cardThreeTitle, 
            cardFourTitle, 

            descSubTitleOne, 
            descSubTitleBee, 
            descSubTitleTwo, 
            descSubTitleThree,

            descParaTextOneOne, 
            descParaTextBeeOne, 
            descParaTextTwoOne, 
            descParaTextThreeOne,
            
            claimio, 
            claimStr,

            handleDescOff,

            handleClickOne, 
            handleClickOneBee, 
            handleClickTwo, 
            handleClickThree,

            handleMobDescOff, 

            handleMobOne, 
            handleMobBee, 
            handleMobTwo, 
            handleMobThree,

            handleClickCollection, 

            handleShowTerms, 
            handleShowPrivacy, 
            handleShowLanding, 
             
            chooseMail, 
            chooseFlyer,

            completeModal,
            visitAppStore
            
        }
    
    }, 
}

</script>

<style>
</style>        