import { ref } from 'vue';
import { useRoute } from 'vue-router'
import { projectFirestore } from '../firebase/config'
import { serverTimestamp } from 'firebase/firestore'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export function adbFunctions() {

    const getAppleApp = () => {
        return 'http://www.getmyaddie.com';
     }

     const getAndroidApp = () => {
        // window.location.href
        return 'http://www.getmyaddie.com';
     }

    const validateEmail = (email) => {

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){ return true } 
        else{ return false } 
    }

    const getAddieId = async (addieid) => {
        try {
            const docRef = projectFirestore.collection('online').doc('addieurls').collection('urls').doc(addieid);
    
            const doc = await docRef.get();
    
            if (doc.exists) {
                return {
                    fetchio: true,
                    flyerror: "",
                    addieio: true,
                    collectionAddy: doc.data().collAddie,
                    collectionCitySt: doc.data().collCitySt,
                    collectionName: doc.data().collName,
                };
            } else {
                return {
                    fetchio: false,
                    flyerror: "Document not found",
                    addieio: false,
                    collectionAddy: "",
                    collectionCitySt: "",
                    collectionName: ""
                };
            }
        } catch (error) {
            return {
                fetchio: false,
                flyerror: "Could not fetch the data",
                addieio: false,
                collectionAddy: "",
                collectionCitySt: "",
                collectionName: ""
            };
        }
    };

    const fetchContactUpdate = async (fetchid) => {
        try {
            
            const docRef = projectFirestore.collection('online').doc('notifications').collection('contactUpdates').doc(fetchid);
    
            const doc = await docRef.get();
    
            if (doc.exists) {

                return {
                    fetchio: true,
                    flyerror: "",
                    addieio: true,
                    collectionAddy: doc.data().addieStr,
                    collectionName: doc.data().senderFullName,
                    collectionSaveId: doc.data().senderCode,
                    collectionCode: doc.data().confirmedZip
                };
            } else {
                return {
                    fetchio: false,
                    flyerror: "Document not found",
                    addieio: false,
                    collectionAddy: "",
                    collectionCitySt: "",
                    collectionName: "",
                    collectionSaveId: "",
                    collectionCode: ""
                };
            }
        } catch (error) {
            return {
                fetchio: false,
                flyerror: "Could not fetch the data",
                addieio: false,
                collectionAddy: "",
                collectionCitySt: "",
                collectionName: "",
                collectionSaveId: "",
                collectionCode: ""
            };
        }
    };

    const saveNoChangesUpdate = async (addressId, addieid, delid) => {
        
        const error = ref("");
        const success = ref(false);

        try {
            error.value = "Error saving to the user..."
            const res = await projectFirestore.collection('users').doc(addieid).collection('addiebook').doc(addressId).update({
                adbUid: addressId,
                requested: false,
                updated: serverTimestamp()
            })

            error.value = "Error removing the old request..."
            const remdel = await projectFirestore.collection('online').doc('notifications').collection('contactUpdates').doc(delid).delete();

            success.value = true
            error.value = ""
            
            return { success: success.value, error: error.value };
        }
        catch(err) {
            success.value = false
            return { success: success.value, error: error.value };
        }
    }

    const saveChangesUpdate = async (saveid, addressId, delid, email, thisaddie, secondary) => {

        var save = ref({ address: "", cityst: "", city: "", state: "", zip: "", street: "", stnum: "", lat: "", lng: "", pid: "" })
        save = thisaddie
        
        const error = ref("");
        const success = ref(false);

        var oldcode = addressId
        console.log(oldcode)
        oldcode = oldcode.slice(0,8)
        console.log(oldcode)
        const newcode = oldcode+save.zip
        console.log(newcode)

        try {
            error.value = "Error fetching the old contact..."
            const oldContact = await projectFirestore.collection('users').doc(saveid).collection('addiebook').doc(addressId).get()
            error.value = "Error getting contact name..."
            const contactName = oldContact.data()["name"]
            error.value = "Error removing the old contact..."
            const del = await projectFirestore.collection('users').doc(saveid).collection('addiebook').doc(addressId).delete()
            error.value = "Error updating the contact..."
            const newContact = await projectFirestore.collection('users').doc(saveid).collection('addiebook').doc(newcode).set({
                adbUid: newcode,
                address: save.address,
                citySt: save.cityst,
                adbZip: save.zip,
                email: email,
                secondary: secondary,
                requested: false,
                updated: serverTimestamp(),
                addieIo: false,
                approved: true,
                mobile: "",
                name: contactName,

            })

            error.value = "Error removing the old request..."
            const remdel = await projectFirestore.collection('online').doc('notifications').collection('contactUpdates').doc(delid).delete();
           
            success.value = true
            error.value = ""
            
            return { savesuccess: success.value, saveerror: error.value };
        }
        catch(err) {
            success.value = false
            return { success: success.value, error: error.value };
        }
    }

    const getAddressData = (addressData, placeResultData) => {

        return {
            address: ''.concat(addressData.street_number,' ',addressData.route),
            cityst: ''.concat(addressData.locality,', ',addressData.administrative_area_level_1),
            city: addressData.locality,
            state: addressData.administrative_area_level_1,
            zip: addressData.postal_code,
            street: addressData.route,
            stnum: addressData.street_number,
            lat: addressData.latitude,
            lng: addressData.longitude,
            pid: placeResultData.place_id
        }

    }

    const handleClickGetApp = async (event) => {
        event.preventDefault();
        console.log('Go get it!');
        window.location.href = 'https://apps.apple.com/us/app/addie-dashboard/id6449395138';
    }

    const abbrState = async (input) => {

        var states = [
            ['Arizona', 'AZ'],
            ['Alabama', 'AL'],
            ['Alaska', 'AK'],
            ['Arkansas', 'AR'],
            ['California', 'CA'],
            ['Colorado', 'CO'],
            ['Connecticut', 'CT'],
            ['Delaware', 'DE'],
            ['Florida', 'FL'],
            ['Georgia', 'GA'],
            ['Hawaii', 'HI'],
            ['Idaho', 'ID'],
            ['Illinois', 'IL'],
            ['Indiana', 'IN'],
            ['Iowa', 'IA'],
            ['Kansas', 'KS'],
            ['Kentucky', 'KY'],
            ['Louisiana', 'LA'],
            ['Maine', 'ME'],
            ['Maryland', 'MD'],
            ['Massachusetts', 'MA'],
            ['Michigan', 'MI'],
            ['Minnesota', 'MN'],
            ['Mississippi', 'MS'],
            ['Missouri', 'MO'],
            ['Montana', 'MT'],
            ['Nebraska', 'NE'],
            ['Nevada', 'NV'],
            ['New Hampshire', 'NH'],
            ['New Jersey', 'NJ'],
            ['New Mexico', 'NM'],
            ['New York', 'NY'],
            ['North Carolina', 'NC'],
            ['North Dakota', 'ND'],
            ['Ohio', 'OH'],
            ['Oklahoma', 'OK'],
            ['Oregon', 'OR'],
            ['Pennsylvania', 'PA'],
            ['Rhode Island', 'RI'],
            ['South Carolina', 'SC'],
            ['South Dakota', 'SD'],
            ['Tennessee', 'TN'],
            ['Texas', 'TX'],
            ['Utah', 'UT'],
            ['Vermont', 'VT'],
            ['Virginia', 'VA'],
            ['Washington', 'WA'],
            ['West Virginia', 'WV'],
            ['Wisconsin', 'WI'],
            ['Wyoming', 'WY'],

        ];

        const upinput = input.toUpperCase();

        for (const state of states) {
            if (state[1] === upinput) {
                return state[0];
            }
        }

        return undefined;
    }

    const generateClaimId = async () => {

        var claimnumber = 0;

        const _eightyMil = [10000000, 20000000, 30000000, 40000000, 50000000, 60000000, 70000000, 80000000, 90000000];

        var _milGen = Math.floor(Math.random() * 9);
        var _chosenMil = _eightyMil.at(_milGen);  //.elementAt(_milGen);

        claimnumber = Math.floor(Math.random() * 1000000) + _chosenMil;

        return claimnumber.toString()
    }

    const submitCollection = async (addieid, email, fname, lname, thisaddie, secondary) => {
        
        var save = ref({ address: "", cityst: "", city: "", state: "", zip: "", street: "", stnum: "", lat: "", lng: "", pid: "" })
        save = thisaddie

        var fullstate = await abbrState(save.state)
        const claimid = await generateClaimId()
        const hbcid = save.zip.replace(" ", "").toString() + save.street.toLowerCase().replace(" ", "").toString() + save.stnum.replace(" ", "").toString() + fullstate.toLowerCase().replace(' ','').toString() + save.city.toLowerCase().replace(' ','').toString()

        const error = ref("")
        const success = ref(false)

        const urlid = ref("")

        try {
            error.value = "Error collecting user credentials..."
            const url = await projectFirestore.collection('online').doc('addieurls').collection('urls').doc(addieid).get()
            urlid.value = url.data()["collRef"]
            error.value = ""
        }
        catch(err) {
            return  { success: success.value, error: error.value }
        }

        try {
            error.value = "Error saving to the user..."
            const res = await projectFirestore.collection('online').doc('addieurls').collection('forms').doc(claimid + save.zip).set({
                email: email,
                fname: fname,
                lname: lname,
                adbcid: hbcid,
                address: save.address,
                cityst: save.city + ", " + save.state,
                stnum: save.stnum,
                street: save.street,
                city: save.city,
                state: save.state,
                zip: save.zip,
                secondary: secondary,
                urlResponseId: urlid.value,
                tempCode: claimid
            })

            success.value = true
            error.value = urlid.value
            
            return { success: success.value, error: error.value };
        }
        catch(err) {
            success.value = false
            return { success: success.value, error: error.value };
        }
    }

    return {
        getAppleApp,
        getAndroidApp,
        validateEmail,
        abbrState,
        generateClaimId,
        getAddieId,
        fetchContactUpdate,
        getAddressData,
        submitCollection,
        saveNoChangesUpdate,
        saveChangesUpdate,
        handleClickGetApp
    };
}