<template>

    <header>
        <div class="hb-header">
            <a href="/">
                <img src="../assets/photos/HB-Header-Icon.png" alt="Addie Icon" class="header-icon">
            </a>
            <ul class="main-nav-list">
                <li><a class="main-nav-link" href="/">How it works</a></li>
                <li><a @click="handleClickGetApp" href="#" class="main-nav-link nav-cta">Get Addie App</a></li>
            </ul>
        </div>
        
    </header>

    <main>
        <div class="reader">
            <h1>Legal</h1>
            <p class="smpl-italic">Last Updated: December 11, 2023</p>
            <h2>Polices, Notices, Disclaimers and Terms Of Use of this Website</h2>
            <h3 class="smpl-bold">BY USING THIS WEBSITE, YOU AGREE TO ALL OF THE FOLLOWING POLICIES, NOTICES, DISCLAIMERS AND TERMS OF USE.</h3>
            <p>Addie LLC operates this website for the limited purpose of disseminating general information to assist the pursuit of qualified employment candidates and to provide general information to vendors and current clients. The website is not intended for any use which would be contrary to applicable law or regulation. In addition, this website is not intended to provide specific financial, accounting, tax or other advice and should not be relied upon for that purpose.This website is not an offer to, or solicitation of, any potential clients or investors. No material listed on this website is or should be construed as investment advice, nor is anything on this website an offer to sell, or a solicitation of an offer to buy, any security or other instrument. It is for information purposes only and is subject to change at any time without prior notice.</p>
            <h3 class="smpl-bold">DISCLAIMER</h3>
            <p>Addie LLC makes no representation or warranty as to the accuracy, adequacy or completeness of the information on this website and expressly disclaims liability for any errors or omissions therein or therefrom. In addition, Addie LLC makes no other representation or warranty, implied, express or statutory, in connection therewith, including (without limitation) fitness for a particular purpose, freedom from computer virus and non-infringement of third party rights.</p>
            <p>In no event will Addie LLC be liable for any damages, including (without limitation) direct or indirect, special, incidental or consequential damages, losses or expenses arising in connection with this website, or in connection with its use or attempted use, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if Addie LLC  is advised of the possibility of such damages, losses or expenses.</p>
            <h3 class="smpl-bold">TRADEMARKS</h3>
            <p>The Addie LLC logo and all associated elements, are the part of the intellectual property of this website and are subject to the copyright laws of the United States and other countries.</p>
            <h3 class="smpl-bold">BETA TERMS OF PARTICIPATION</h3>
            <p>Subject to the terms and conditions of this Agreement, Company grants Recipient a nonexclusive, nontransferable license to use the Addie iPhone App ("Service") for a period designated by the Addie, LLC (“Company”) for the purpose of testing and evaluating the Service.</p>
            <p>1. The Recipient agrees that nothing contained in this Agreement shall be construed as granting any ownership rights to any Confidential Information disclosed pursuant to this Agreement, or to any invention or any patent, copyright, trademark, or other intellectual property right. The Recipient shall not make, have made, use or sell for any purpose any product or other item using, incorporating or derived from any Confidential Information or the Service. The Recipient will not modify, reverse engineer, decompile, create other works from, or disassemble any software programs contained in the Confidential Information or the Service.</p>
            <p>2. This Service is a beta release offering and is not at the level of performance of a commercially available product offering. The Service may not operate correctly and may be substantially modified prior to first commercial release, or at Company's option may not be released commercially in the future.</p>
            <p>THE SERVICE AND DOCUMENTATION ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, AND COMPANY AND ITS LICENSORS DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. NO ORAL OR WRITTEN ADVICE OR CONSULTATION GIVEN BY COMPANY, ITS AGENTS OR EMPLOYEES WILL IN ANY WAY GIVE RISE TO A WARRANTY. THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF THE SERVICE REMAINS WITH RECIPIENT.</p>
            <p>3. COMPANY AND ITS LICENSORS SHALL NOT BE LIABLE FOR LOSS OF USE, LOST PROFIT, COST OF COVER, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO THE SERVICE OR THIS AGREEMENT, HOWEVER CAUSED AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) STRICT LIABILITY, OR OTHERWISE, EVEN IF SUCH PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </p>
            <p>IN NO EVENT WILL COMPANY'S AGGREGATE CUMULATIVE LIABILITY FOR ANY CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED $50.00 OR THE AMOUNT RECIPIENT ACTUALLY PAID COMPANY UNDER THIS AGREEMENT (IF ANY).</p>
            <p>4. The Recipient's obligations under this Agreement shall survive any termination of this agreement. This Agreement shall be governed by and construed in accordance with the laws of Mississippi. The Recipient hereby agrees that breach of this Agreement will cause Company irreparable damage for which recovery of damages would be inadequate, and that the Company shall therefore be entitled to obtain timely injunctive relief under this Agreement, as well as such further relief as may be granted by a court of competent jurisdiction. The Recipient will not assign or transfer any rights or obligations under this Agreement without the prior written consent of the Company.</p>
            <p>5. Submission of an email address by the Recipient to the Company and subsequent downloading of the Service from the Test Flight App shall act as an agreement by the Recipient to this Agreement. This agreement shall apply to third parties that may bring claims if the third parties use the Recipients email address and subsequent downloads on their own devices, or the Recipient downloads the Service onto a device not owned by the Recipient.</p>
            
            <h2>Privacy Notifications</h2>
            <h3 class="smpl-bold">SUBMISSIONS</h3>
            <p>Please do not send any information to Addie LLC that constitutes a trade secret or is confidential or proprietary. Addie LLC  cannot agree to obligations of confidentiality or non-disclosure with regard to information submitted to it. By submitting information or materials to Addie LLC, you or anyone acting on your behalf, agree that any such information or materials will not be considered confidential or proprietary.</p>
            <h3 class="smpl-bold">SECURITY</h3>
            <p>This Website is not designed to provide security against unauthorized interception or use of information you may provide through your use of it. Do not transmit confidential or sensitive information to us over this Website.</p>
            <h3 class="smpl-bold">COPYRIGHT</h3>
            <p>This Website was created by Addie LLC, which owns the copyright interest therein. Use and/or reproduction of any portion of this Website is limited as described in the Permitted Use section of this page, and is subject to the Disclaimer and other cautions appearing on this page, all as may be updated or changed at any time.</p>
            <h3 class="smpl-bold">PERMITTED USE</h3>
            <p>You are granted permission to view, store, copy and print any of the pages of this Website. You are also granted permission to distribute any one or more of these pages, provided you do so without making any alteration and that this page is included with the other page(s) you distribute.</p>
            <p class="smpl-italic">We reserve the right to change these statements. Any revision to these statements and changes will be effective as of the date of posting.</p>
           
            <h1>Addie App Legal</h1>
            <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" class="main-nav-link nav-cta smpl-top-space-sm">View iOS Terms of Notifications</a>    
            <h2>Addie App Privacy Notifications</h2>
            <h3 class="smpl-bold">INTRODUCTION</h3>
            <p>Welcome to Addie, a mobile application ("App") developed and published by Addie, LLC ("we," "us," or "our"). This Privacy Policy outlines how we collect, use, share, and protect your information when you use the Addie App. By accessing or using the App, you agree to the terms outlined in this Privacy Policy.</p>
            <h3 class="smpl-bold">INFORMATION WE COLLECT</h3>
            <p>Personal Information: When you create an account, we may collect personal information such as your name, email address, and profile picture.</p>
            <p>Usage Data: We collect data related to your use of the App, including app interactions, preferences, and device information.</p>
            <p>Location Information: With your consent, we may collect and use your precise location data to provide location-based services.</p>
            <h3 class="smpl-bold">HOW WE USE YOUR INFORMATION</h3>
            <p>Provide and Improve the App: We use the collected information to deliver, maintain, and enhance the functionality of the App.</p>
            <p>Personalization: We may use your information to personalize your experience and provide tailored content and recommendations.</p>
            <p>Communication: We may use your email address to send you important updates, notifications, or promotional messages related to the App.</p>
            <h3 class="smpl-bold">SHARING YOUR INFORMATION</h3>
            <p>Service Providers: We may share your information with third-party service providers who assist us in delivering and improving the App.</p>
            <p>Legal Compliance: We may disclose your information if required by law or in response to a valid legal request.</p>
            <h3 class="smpl-bold">YOUR CHOICES</h3>
            <p>Account Information: You can review and update your account information by accessing your account settings within the App.</p>
            <p>Location Services: You can control location tracking through your device settings.</p>
            <p>Communication Preferences: You can manage your communication preferences by adjusting settings within the App.</p>
            <h3 class="smpl-bold">SECURITY</h3>
            <p>We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction.</p>
            <h3 class="smpl-bold">CHILDREN'S PRIVACY</h3>
            <p>The App is not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If you believe a child has provided us with personal information, please contact us, and we will take appropriate action.</p>
            <h3 class="smpl-bold">CONTACT US</h3>
            <p>For any questions, concerns, or requests regarding this Privacy Policy, please contact us at hello@getmyaddie.com.</p>
            <h3 class="smpl-bold">UNITED STATES OR CANADA</h3>
            <p>Addie App is available through the App Store in the United States and Canada. This Privacy Policy is intended for users in the United States and Canada. By using the App, you acknowledge and agree to the processing of your information in accordance with this Privacy Policy. You should not use this App if you are located outside of the United States or Canada.</p>
            
            <p class="smpl-italic">We reserve the right to change these statements. Any revision to these statements and changes will be effective as of the date of posting. We will notify you of significant changes by posting the updated policy within the App.</p>
            <p>Thank you for choosing Addie!</p>
        </div>
    </main>
    
</template>

<script>
import { ref, mounted, watch } from 'vue'
import { useRouter } from 'vue-router'

export default {

    name: "Legal",

    components: { },
    
    setup() {

        const router = useRouter()

        const showLanding = ref(true)
        const showTerms = ref(false)
        const showPrivacy = ref(false)

        const activeColorOne = ref(null)
        const activeColorTwo = ref(null)
        const activeColorThree = ref(null)

        const activeColorOneMob = ref(null)
        const activeColorTwoMob = ref(null)
        const activeColorThreeMob = ref(null)

        const iconOne = ref(false)
        const iconOneBee = ref(true)
        const iconTwo = ref(true)
        const iconThree = ref(true)

        const mobOne = ref(false)
        const mobBee = ref(false)
        const mobTwo = ref(false)
        const mobThree = ref(false)

        const mailorflyer = ref(true)

        const cardOneTitle = ref("a dashboard for your home")
        const cardTwoTitle = ref("build your addie score")
        const cardThreeTitle = ref("access your mailbox on your terms")
        const cardFourTitle = ref("stay in touch with your address book")
        
        const descSubTitleOne = ref("Your home at your fingertips")
        const descSubTitleBee = ref("Sell your home commission free")
        const descSubTitleTwo = ref("Get paid to open your mail")
        const descSubTitleThree = ref("People move; don't lose touch")

        const handleClickGetApp = async (event) => {
            event.preventDefault();
            console.log('Go get it!');
            window.location.href = 'https://apps.apple.com/us/app/addie-dashboard/id6449395138';
        }

        const handleClickOne = () => {
            
            iconOne.value = false
            iconOneBee.value = true
            iconTwo.value = true
            iconThree.value = true

            activeColorOne.value.classList.add("active")
            activeColorTwo.value.classList.remove("active")
            activeColorThree.value.classList.remove("active")

        }

        const handleClickOneBee = () => {
            
            iconOne.value = true
            iconOneBee.value = false
            iconTwo.value = true
            iconThree.value = true

            activeColorOne.value.classList.add("active")
            activeColorTwo.value.classList.remove("active")
            activeColorThree.value.classList.remove("active")

        }

        const handleClickTwo = () => {
            
            iconOne.value = true
            iconOneBee.value = true
            iconTwo.value = false
            iconThree.value = true

            activeColorOne.value.classList.remove("active")
            activeColorTwo.value.classList.add("active")
            activeColorThree.value.classList.remove("active")

        }

        const handleClickThree = () => {
            
            iconOne.value = true
            iconOneBee.value = true
            iconTwo.value = true    
            iconThree.value = false

            activeColorOne.value.classList.remove("active")
            activeColorTwo.value.classList.remove("active")
            activeColorThree.value.classList.add("active")

        }

        const handleMobOne = () => {
            
            mobOne.value = true
            mobBee.value = false
            mobTwo.value = false
            mobThree.value = false

            activeColorOneMob.value.classList.add("active")
            activeColorTwoMob.value.classList.remove("active")
            activeColorThreeMob.value.classList.remove("active")
        }

        const handleMobBee = () => {
            
            mobOne.value = false
            mobBee.value = true
            mobTwo.value = false
            mobThree.value = false

            activeColorOneMob.value.classList.add("active")
            activeColorTwoMob.value.classList.remove("active")
            activeColorThreeMob.value.classList.remove("active")
        }

        const handleMobTwo = () => {
            
            mobOne.value = false
            mobBee.value = false
            mobTwo.value = true
            mobThree.value = false

            activeColorOneMob.value.classList.remove("active")
            activeColorTwoMob.value.classList.add("active")
            activeColorThreeMob.value.classList.remove("active")
        }

        const handleMobThree = () => {
            
            mobOne.value = false
            mobBee.value = false
            mobTwo.value = false
            mobThree.value = true

            activeColorOneMob.value.classList.remove("active")
            activeColorTwoMob.value.classList.remove("active")
            activeColorThreeMob.value.classList.add("active")
        }
        
        const handleShowTerms = () => {
            showLanding.value = false;
            showTerms.value = true;
        }  
        
        const handleShowPrivacy = () => {
            showLanding.value = false;
            showPrivacy.value = true;
        }  

        const handleShowLanding = () => {
            showPrivacy.value = false;
            showTerms.value = false;
            showLanding.value = true;
        }  

        const chooseMail = () => {
            mailorflyer.value = true
        }

        const chooseFlyer = () => {
            mailorflyer.value = false
        }

        return { 
            cardOneTitle, 
            cardTwoTitle, 
            cardThreeTitle, 
            cardFourTitle, 
            descSubTitleOne, 
            descSubTitleBee, 
            descSubTitleTwo, 
            descSubTitleThree,
            mobOne, 
            mobBee, 
            mobTwo, 
            mobThree,
            iconOne, 
            iconOneBee, 
            iconTwo, 
            iconThree, 
            mailorflyer, 
            activeColorOne, 
            activeColorTwo, 
            activeColorThree, 
            activeColorOneMob, 
            activeColorTwoMob, 
            activeColorThreeMob,
            showLanding, 
            showTerms, 
            showPrivacy,
            handleClickOne, 
            handleClickOneBee, 
            handleClickTwo, 
            handleClickThree, 
            handleClickGetApp, 
            chooseMail, 
            chooseFlyer,
            handleMobOne, 
            handleMobBee, 
            handleMobTwo, 
            handleMobThree,
            handleShowTerms, 
            handleShowPrivacy, 
            handleShowLanding
        }
    
    }

}
</script>

<style>

</style>