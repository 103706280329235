import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyCtQjYi_6bEuKiXs1S9VqJTosQQGS_mObI",
    authDomain: "getmyaddie.firebaseapp.com",
    databaseURL: "https://getmyaddie-default-rtdb.firebaseio.com",
    projectId: "getmyaddie",
    storageBucket: "getmyaddie.appspot.com",
    messagingSenderId: "758898434998",
    appId: "1:758898434998:web:2d3c0ff8662904305fd717",
    measurementId: "G-YRHE22HHM6"
  };



// init firebase
firebase.initializeApp(firebaseConfig)

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
const timestamp = firebase.firestore.FieldValue.serverTimestamp
const opt = firebase.firestore

export { projectAuth, projectFirestore, timestamp, opt }