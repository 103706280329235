<template>
    <div class="backdrop" > 
       <div v-if="!addedIo" >
         <div v-if="type === 'submit'" class="adbmodal"> 
            <h1 class="modal-h1">Submit Addie Info</h1>
            
            <div>
                <p class="modal-p"> Share address with {{name}}</p>
               
                <input class="signup-field sup-name" type="first name" required placeholder="First Name" v-model="fname">
                <input class="signup-field sup-name" type="last name" required placeholder="Last Name" v-model="lname">
                <input class="signup-field sup-email" type="email" required placeholder="Email" v-model="email">
                <vue-google-autocomplete
                    v-model="addressCheck"
                    ref="address"
                    id="map"
                    required placeholder="Type address and choose from list"
                    v-on:placechanged="chooseAddie"
                    country="us"
                    class="signup-field sup-address"
                ></vue-google-autocomplete>

                <input class="signup-field sup-sec" type="secondary" required placeholder="Opt: Unit# (no spaces: numbers, letters only)" v-model="secondary">

                <div>
                    <div v-if="!namesIo" class="modal-listtxt modal-addtoppad">• Must enter first and last name</div>
                    <div v-if="namesIo" class="modal-listtxt modal-addtoppad modal-listtxt-complete">• Must enter first and last name</div>
                    <div v-if="!emailIo" class="modal-listtxt">• Must enter a valid email address</div>
                    <div v-if="emailIo" class="modal-listtxt modal-listtxt-complete">• Must enter a valid email address</div>
                    <div v-if="!addressIo" class="modal-listtxt">• Must type and choose address from list</div>
                    <div v-if="addressIo" class="modal-listtxt modal-listtxt-complete">• Must type and choose address from list</div>
                </div>

                <a v-if="!liveBtnIo" class="collection-chooser-dead">Submit Address</a>
                <a v-if="liveBtnIo" @click="submitAddie" class="collection-chooser">Submit Address</a>
                <a class="modal-txtbtn" @click="closeModal"><div>close</div></a>

            </div>
        </div>
        <div v-if="type === 'update'" class="adbmodal"> 
            <h1 class="modal-h1">Update Addie Info</h1>
            
            <div>
                <p class="modal-p"> Update your contact and address from:</p>
                <p class="modal-p smpl-bold"> {{ address }}</p>
               
                <input class="signup-field sup-email" type="email" required placeholder="Best email" v-model="email">
                <vue-google-autocomplete
                    v-model="addressCheck"
                    ref="address"
                    id="map"
                    required placeholder="Type address and choose from list"
                    v-on:placechanged="chooseAddie"
                    country="us"
                    class="signup-field sup-address"
                ></vue-google-autocomplete>

                <input class="signup-field sup-sec" type="secondary" required placeholder="Opt: Unit# (no spaces: numbers, letters only)" v-model="secondary">

                <div>
                    <div v-if="!emailIo" class="modal-listtxt">• Must enter a current email address, even if same</div>
                    <div v-if="emailIo" class="modal-listtxt modal-listtxt-complete">• Must enter a current email address, even if same</div>
                    <div v-if="!addressIo" class="modal-listtxt">• Must type and choose address from list</div>
                    <div v-if="addressIo" class="modal-listtxt modal-listtxt-complete">• Must type and choose address from list</div>
                </div>

                <a v-if="!liveBtnIo" class="collection-chooser-dead">Submit Update</a>
                <a v-if="liveBtnIo" @click="submitUpdate" class="collection-chooser">Submit Update</a>
                <a class="modal-txtbtn" @click="closeModal"><div>close</div></a>

            </div>
        </div>
       </div>
       
       <div v-if="addedIo && gotItIo"> 
        <div class="adbmodal"> 
            <h1 class="modal-h1">Thanks for submitting!</h1>
            <p class="modal-p">🏡 We reserved your address just in case you want to grab your Addie like {{name}}! 🎉🎉 Ready to claim your Addie? Download the Addie app from the app store and follow the instructions sent to your email! Let's unlock the magic of managing your home together! ✨</p>
            <!-- <a class="collection-join-btn class='white-txt'"><div><a @click="getAndroidApp">Claim Your Address on Android</a></div></a> -->
            <a class="collection-join-btn iphone-color class='white-txt'"><div><a @click="visitAppStore">Claim Your Address on iPhone</a></div></a> 
            <a class="modal-txtbtn" @click="completeModal"><div>no thanks</div></a>
        </div>
       </div>

       <div v-if="addedIo && retryIo"> 
        <div class="adbmodal"> 
            <h1 class="modal-h1">Ooops, something went wrong!</h1>
            <p class="modal-p">🏡 Oops! It looks like there was a hiccup in saving your information. Don't worry, let's give it another shot! Click the button below to retry. We're here to make sure everything runs smoothly for you! 🛠️</p>
            <!-- <a class="collection-join-btn class='white-txt'"><div><a @click="getAndroidApp">Claim Your Address on Android</a></div></a> -->
            <a class="collection-join-btn iphone-color class='white-txt'"><div><a @click="resetForm">Let's Try Again!</a></div></a> 
            <a class="modal-txtbtn" @click="closeModal"><div>cancel</div></a>
        </div>
       </div>

    </div>
</template>

<script>

import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { adbFunctions } from '../models/functions.js';
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { projectFirestore, opt } from '../firebase/config'

export default {
    name: "Input Modal",
    components: { VueGoogleAutocomplete, projectFirestore },
    props: ['type', 'name', 'saveid', 'address', 'code', 'delid' ],
    methods: {
        closeModal() {
            this.$emit('close')
        },
        completeModal() {
            this.$emit('complete')
        }
    },

    setup(props, methods) {

        const email = ref('');
        const addressCheck = ref('');

        const fname = ref('');
        const lname = ref('');

        const thisaddie = ref({ address: "", cityst: "", city: "", state: "", zip: "", street: "", stnum: "", lat: "", lng: "", pid: "" });
        const secondary = ref('');

        const { getAddressData, validateEmail, submitCollection, saveChangesUpdate, getAppleApp } = adbFunctions();

        const addedIo = ref(false);
        const retryIo = ref(false);
        const gotItIo = ref(false);

        const submitAddie = async () => {
            const { success, error } = await submitCollection(props.saveid, email.value, fname.value, lname.value, thisaddie.value, secondary.value);
            
            addedIo.value = true;
            console.log(error)
            if(success) { gotItIo.value = true; }
            else { retryIo.value = true; }
        }

        const submitUpdate = async () => {
            const { savesuccess, saveerror } = await saveChangesUpdate(props.saveid, props.code, props.delid, email.value, thisaddie.value, secondary.value);
            
            addedIo.value = true;
            
            if(savesuccess) { gotItIo.value = true; }
            else { retryIo.value = true; }
        }

        const chooseAddie = (addressData, placeResultData, id) => {
            thisaddie.value = getAddressData(addressData, placeResultData, id) 
            addressIo.value = true
        }

        const visitAppStore = (event) => {
            event.preventDefault();
            console.log('Go get it!');
            window.location.href = 'https://apps.apple.com/us/app/addie-dashboard/id6449395138';
        }

        const resetForm = () => {
            thisaddie.value = ref({ address: "", cityst: "", city: "", state: "", zip: "", street: "", stnum: "", lat: "", lng: "", pid: "" });
            addressCheck.value = '';
            email.value = '';
            fname.value = '';
            lname.value = '';
            secondary.value = '';
            addedIo.value = false;
            retryIo.value = false;
            gotItIo.value = false;
            namesIo.value = false;
            emailIo.value = false;
            addressIo.value = false;
            liveBtnIo.value = false;
        }

        const namesIo = ref(false)
        const emailIo = ref(false)
        const addressIo = ref(false)
        const liveBtnIo = ref(false)

        watch([addressIo, email, fname, lname], () => {

            if(addressCheck === "") { addressIo = false }

            if(props.type === "submit") {

                namesIo.value = fname.value !== "" && lname.value !== ""
                emailIo.value = validateEmail(email.value)

                if(emailIo.value && addressIo.value && thisaddie.value.pid !== "" && fname.value !== "" && lname.value !== ""){ liveBtnIo.value = true }
                else{  liveBtnIo.value = false }
            } 

            if(props.type === "update") {

                emailIo.value = validateEmail(email.value)

                if(emailIo.value && addressIo.value && thisaddie.value.pid !== ""){ liveBtnIo.value = true }
                else{  liveBtnIo.value = false }
            }

        })


        return {
            email,
            fname,
            lname,
            secondary,
            addressCheck,
            namesIo,
            emailIo,
            addressIo,
            liveBtnIo,
            addedIo,
            retryIo,
            gotItIo,
            submitAddie,
            submitUpdate,
            chooseAddie, 
            visitAppStore,
            resetForm
        }
    }
}
</script>


<style>

.modal-h1 {
    color: #484848;
    font-size: 2.6rem;
    font-weight: bold;
    padding-top: 2.5rem;
    padding-bottom: 15px;
    padding-left: 2.5rem;
}

.modal-p {
    color: #484848;
    font-size: 1.8rem;
    line-height: 1.4;
    padding-top: 4px;
    padding-bottom: 15px;
    padding-left: 2.5rem;
}

.adbmodal {
    width: 90%;
    max-width: 500px;
    padding: 20px;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
}

.modal-txtbtn {
    display: flex;
    justify-content: center; /* Horizontally center the button */
    align-items: center;
    text-decoration: none;
    color:  #484848;
    font-weight: bold;
    font-size: 16px;
    padding-top: 1rem;
    padding-bottom: 4rem;
}

.modal-listtxt {
    display: flex;
    align-items: center;
    text-decoration: none;
    color:  #484848;
    font-size: 10px;
    padding-top: 0.5rem;
    padding-left: 2.5rem;
}

.modal-listtxt-complete {
    color: white;
}

.modal-addtoppad {
    padding-top: 2.5rem;
}

.backdrop {
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
}

</style>